body {
  background-color: #1a1a1a;
  color: #ffffff;
  font-family: 'Ubuntu Mono', monospace;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s ease;
}

:root {
    --background-color: #1e1e1e;
    --text-color: #f0f0f0;
    --input-background: #2c2c2c;
    --input-border: #3a3a3a;
    --button-background: #4caf50;
    --button-hover: #45a049;
    --error-color: #e57373;
    --border-radius: 8px;
    --transition: 0.3s ease;
}

.download-spoofer {
    text-align: center;
    margin: 50px auto;
    padding: 20px;
    background-color: #2c2c2c;
    border-radius: var(--border-radius);
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.download-spoofer h1 {
    color: #ffffff;
    margin-bottom: 20px;
}

.download-spoofer input {
    width: calc(100% - 24px);
    padding: 12px;
    margin: 10px 0;
    background-color: var(--input-background);
    border: 1px solid var(--input-border);
    border-radius: var(--border-radius);
    color: var(--text-color);
    font-size: 16px;
    transition: border-color var(--transition), background-color var(--transition);
}

.download-spoofer input:focus {
    border-color: var(--button-background);
    background-color: #3c3c3c;
    outline: none;
}

.download-spoofer button {
    width: 100%;
    padding: 12px;
    background-color: var(--button-background);
    border: none;
    border-radius: var(--border-radius);
    color: var(--text-color);
    font-size: 16px;
    cursor: pointer;
    transition: background-color var(--transition);
}

.download-spoofer button:hover {
    background-color: var(--button-hover);
}

.download-spoofer .error {
    color: var(--error-color);
    margin-top: 10px;
    font-size: 14px;
}

a {
  text-decoration: none;
  color: #3755ff;
  transition: color 0.3s ease;
}

a:hover {
  color: #6699ff;
}

#main {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#title {
  font-size: 42px;
  line-height: 1.2;
  margin-bottom: 10px;
}

#subtitle {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 0;
}

#subtitle::after {
  content: '';
  display: block;
  width: 150px;
  height: 2px;
  background-color: #3755ff;
  margin: 10px auto;
}

.raindrop {
  position: fixed;
  top: -20px;
  left: 0;
  color: green;
  font-size: 16px;
  animation: rain 3s linear infinite, fadeInOut 1.5s ease infinite;
  z-index: 0; /* Ensure raindrops are below other content */
}

@keyframes rain {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(370vh); /* Changed to 110vh to ensure raindrops reach bottom */
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  #title {
    font-size: 36px;
  }

  #subtitle {
    font-size: 14px;
  }

  .clock {
    font-size: 36px;
    top: 10px;
    right: 10px;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

.user-area {
  cursor: pointer;
  color: #3755ff;
  font-size: 20px;
  margin-top: 20px;
  text-decoration: underline;
  font-family: 'Arial', sans-serif;
  transition: color 0.3s;
  text-align: center;
  display: block;
}

.user-area:hover {
  color: #2b45d4;
}

#main {
  padding-top: 50px;
  text-align: center;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #700808;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  z-index: 1000;
  animation: fadein 0.5s, fadeout 0.5s 3s; /* Adjusted fadeout delay to 3s */
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to { opacity: 0; }
}

.changelog-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  color: #3755ff;
  font-size: 12px; /* Small font size as requested */
  padding: 5px 10px;
  border: 2px solid #3755ff;
  border-radius: 5px;
  background: transparent;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
  z-index: 1000; /* Ensure button is above raindrops */
}

.changelog-button:hover {
  background-color: #3755ff;
  color: #fff;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Existing styles */
.modal-content {
  background: #1a1a1a;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #700808;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-close:hover {
  background: #900;
}

.changelog-entry {
  margin-bottom: 10px;
}

.changelog-date {
  display: block;
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}


.modal-content h2 {
  margin-top: 0;
}

.modal-content ul {
  list-style: none;
  padding: 0;
}

.modal-content li {
  border-bottom: 1px solid #333;
  padding: 10px 0;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.modal-close {
    position: absolute;
    top: 15px;
    right: 20px;
    background: #c90202; /* Slightly less intense red for better visual appeal */
    color: #fff;
    border: none;
    padding: 8px 16px; /* Adjusted padding for better size */
    border-radius: 50px; /* More rounded for a modern look */
    cursor: pointer;
    font-family: 'Roboto', sans-serif; /* Use a clean, modern font */
    font-size: 14px; /* Adjusted font size for readability */
    font-weight: 500; /* Slightly bolder for better visibility */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
    transition: background 0.3s, box-shadow 0.3s; /* Smooth transitions for hover effects */
}

.modal-close:hover {
    background: #d85151; /* Darker red on hover for better feedback */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.modal-close:focus {
    outline: none; /* Removes default focus outline */
}

.info-button {
  position: fixed;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  color: #3755ff;
  font-size: 12px; /* Small font size as requested */
  padding: 5px 10px;
  border: 2px solid #3755ff;
  border-radius: 5px;
  background: transparent;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
  z-index: 1000; /* Ensure button is above raindrops */
}

.info-button:hover {
  background-color: #3755ff;
  color: #fff;
}

.info-button:focus {
  outline: none;
}

/* INFO Message Styles */
.info-message {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #999;
  background: #1a1a1a;
  border: 1px solid #333;
  padding: 10px;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px; /* Smaller font size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
