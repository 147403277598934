/* General Container Styles */
.deceit-container {
    background: #1e1e2f;
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);
    color: #f0f0f0;
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin: 50px auto;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/* Header Styles */
h1 {
    font-size: 2.8rem;
    margin-bottom: 25px;
    color: #03dac6;
    text-shadow: 0 4px 12px rgba(3, 218, 198, 0.5);
    letter-spacing: 1.5px;
}

/* Subheader Styles */
h2 {
    font-size: 2rem;
    margin-top: 40px;
    color: #bb86fc;
    text-shadow: 0 2px 8px rgba(187, 134, 252, 0.5);
}

/* Customer Satisfaction Styles */
h2.satisfaction {
    color: #03dac5;
    margin-top: 30px;
    font-size: 1.6rem;
    text-shadow: 0 2px 8px rgba(3, 218, 197, 0.6);
}

/* Paragraph Styles */
p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 20px 0;
}

/* Showcase Container Styles */
.showcase {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
}

.image-container {
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
    background: rgba(30, 30, 60, 0.8);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.image-container img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

/* Key Features List Styles */
ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    text-align: left;
}

ul li {
    background: rgba(40, 40, 70, 0.9);
    padding: 15px 20px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    font-size: 1.1rem;
    color: #e0e0e0;
}

ul li strong {
    color: #03dac6;
}

ul li:hover {
    background: rgba(50, 50, 90, 0.9);
    transition: background 0.3s ease;
}

/* Contact Information Styles */
.contact-info {
    margin-top: 40px;
    font-size: 1.2rem;
    color: #bb86fc;
    text-shadow: 0 2px 8px rgba(187, 134, 252, 0.5);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .deceit-container {
        padding: 30px;
        margin: 30px;
    }

    h1 {
        font-size: 2.4rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    p {
        font-size: 1rem;
    }

    .image-container {
        max-width: 100%;
    }

    ul li {
        font-size: 1rem;
    }

    .contact-info {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    p {
        font-size: 0.9rem;
    }

    ul li {
        font-size: 0.9rem;
    }

    .contact-info {
        font-size: 0.9rem;
    }
}
